<template>
  <v-dialog v-model="dialog" width="400">
    <v-card>
      <v-card-title>
        {{ invoice.title }}
      </v-card-title>

      <v-card-text class="card-comments">
        <div v-for="(error, idx) in invoice.sendingErrors.slice().reverse()" :key="idx + 'c'">
          <div class="mb-1">
            <small style="font-size: 12px"
              ><strong>{{ formatDate(error.date) }}</strong></small
            >
            <p style="margin-bottom: -4px; font-size: 14px">
              {{ error.text }}
            </p>
            <v-divider v-if="idx < invoice.sendingErrors.length - 1" class="mt-2 mb-2"></v-divider>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "@/mixins/mixins";
export default {
  props: {
    invoice: {
      type: Object,
      default() {
        return {};
      },
    },
    value: { type: Boolean, default: false },
  },

  mixins: [mixins],

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
