var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.currentInvoice.currentRentIncrease.activated)?_c('div',[_c('h3',{staticClass:"mt-1 mb-1"},[_vm._v(" Vuokrankorotus tulossa "),_c('span',[_c('v-icon',{attrs:{"color":"warning","medium":""}},[_vm._v("mdi-alert-circle")])],1)]),(_vm.rentIncreaseDueDate)?_c('p',[_vm._v(" Korotetun laskun ensimmäinen eräpäivä (vuokrankorotuspäivä) on "),_c('strong',[_vm._v(" "+_vm._s(_vm.formatDate(_vm.rentIncreaseDueDate)))])]):_vm._e(),(_vm.rentIncreaseBillDate)?_c('p',[_vm._v(" Lähetyspäivä on "),_c('strong',[_vm._v(" "+_vm._s(_vm.formatDate(_vm.rentIncreaseBillDate))+" ")]),(_vm.paymentTermAlert)?_c('span',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle")])]}}],null,false,47605746)},[_c('span',[_vm._v("Seuraavan laskun ja korotetun laskun lähetyspäivissä pitää olla vähintään 2 päivän ero. Pidennä toistuvien laskujen maksuehtoa, siirrä seuraavan laskun eräpäivää tai muuta toistuvaa eräpäivää.")])])],1):_vm._e()]):_vm._e(),_c('p',[_vm._v(" Maksuehto on "),_c('strong',[_vm._v(_vm._s(_vm.currentInvoice.currentRentIncrease.paymentTerm)+" pv netto")]),_vm._v(" ja lähetystapa "),_c('strong',[_vm._v(_vm._s(_vm.formatSendType(_vm.currentInvoice.currentRentIncrease.sendType)))]),(_vm.sendTypeAlert)?_c('span',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle")])]}}],null,false,47605746)},[_c('span',[_vm._v("Seuraavan laskun lähetystapaa vastaavia tietoja puuttuu laskulta.")])])],1):_vm._e()]),_c('p',[_vm._v(" Verollinen loppusumma on "),_c('strong',[_vm._v(_vm._s(_vm.formatCurrency(_vm.priceWithTaxes)))])]),_c('p',[_vm._v(" Sopimuksen mukainen vuokrankorotuksen ajankohta on "),_c('strong',[_vm._v(_vm._s(_vm.possibleRentIncreaseMonth))])]),_c('p',[_vm._v(" Korotukseen merkitty ensimmäinen mahdollinen laskutusajankohta on "),_c('strong',[_vm._v(_vm._s(_vm.possibleDueDateMonth))])])]):_c('div',[_c('h3',{staticClass:"mb-1 mt-1"},[_vm._v(" Vuokrankorotus aktivoitu "),_c('span',[_c('v-icon',{attrs:{"color":"success","medium":""}},[_vm._v("mdi-check-circle")])],1)]),_c('p',[_vm._v("Korotetut tuotteet on tallennettu laskulle ja lasku odottaa ensimmäistä lähetystä.")]),(_vm.rentIncreaseDueDate)?_c('p',[_vm._v(" Eräpäivä on "),_c('strong',[_vm._v(" "+_vm._s(_vm.formatDate(_vm.rentIncreaseDueDate))+" ")]),(_vm.rentIncreaseDueDateAlert)?_c('span',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle")])]}}],null,false,47605746)},[_c('span',[_vm._v("Korotetut tuotteet on aktivoitu laskulle, mutta olet aikaistanut seuraavaa eräpäivää. Voit lähettää korotetut tuotteet vasta "+_vm._s(_vm.possibleDueDateMonth)+" alkaen.")])])],1):_vm._e()]):_vm._e(),(_vm.rentIncreaseBillDate)?_c('p',[_vm._v(" Lähetyspäivä on "),_c('strong',[_vm._v(" "+_vm._s(_vm.formatDate(_vm.rentIncreaseBillDate))+" ")])]):_vm._e(),_c('p',[_vm._v(" Maksuehto on "),_c('strong',[_vm._v(_vm._s(_vm.currentInvoice.nextPaymentTerm)+" pv netto")]),_vm._v(" ja lähetystapa "),_c('strong',[_vm._v(_vm._s(_vm.formatSendType(_vm.currentInvoice.nextSendType))+" ")]),(_vm.sendTypeAlert)?_c('span',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle")])]}}],null,false,47605746)},[_c('span',[_vm._v("Lähetystapaa vastaavia tietoja puuttuu laskulta.")])])],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }