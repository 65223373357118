<template>
  <v-dialog v-model="dialog" width="500" :persistent="loading">
    <v-card>
      <v-card-title> Muuta laskun {{ currentInvoice.invoiceNumber }} eräpäivää</v-card-title>
      <v-card-subtitle style="margin-top: -9px">
        <strong> Nykyinen eräpäivä on {{ formatDate(currentInvoice.dueDate) }} </strong>
      </v-card-subtitle>
      <!-- Can change -->
      <v-card-text v-show="canChangeDueDate()" class="mt-2">
        <v-form ref="form">
          <v-menu
            ref="dueDateMenu"
            v-model="dueDateMenu"
            :close-on-content-click="false"
            min-width="290"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(dueDate)"
                v-bind="attrs"
                :rules="validations.required"
                outlined
                dense
                v-on="on"
                append-icon="mdi-calendar"
                label="Uusi eräpäivä"
                v-prevent-manual-input
                v-prevent-paste
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dueDate"
              :allowed-dates="allowedDueDates"
              first-day-of-week="1"
              @input="dueDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-form>
      </v-card-text>

      <!-- Can not change -->
      <v-card-text v-show="!canChangeDueDate()">
        <p v-html="getDueDateWarningText()"></p>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false" :disabled="loading">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="canChangeDueDate()" color="info" :loading="loading" @click="submit()"
          >Muuta eräpäivä</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import invoiceMixins from "../../mixins/invoiceMixins";
import validations from "@/validations";
import { mapActions, mapState } from "vuex";

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  mixins: [mixins, invoiceMixins],

  data() {
    return {
      loading: false,
      dueDate: "",
      dueDateMenu: false,
      dateFormattedDueDate: "",
      validations,
    };
  },

  computed: {
    ...mapState("invoice", ["currentInvoice"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) this.$refs.form.reset();
        this.$emit("input", value);
      },
    },
  },

  methods: {
    ...mapActions("invoice", ["changeDueDate"]),

    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          await this.changeDueDate(this.dueDate);
          this.loading = false;
          this.dialog = false;
          this.showPopup(
            `Laskun nro. ${
              this.currentInvoice.invoiceNumber
            } eräpäivää siirrettiin ${this.formatDate(this.dueDate)}`,
            "success"
          );
        } catch (err) {
          this.loading = false;
          this.showPopup(err, "error");
        }
      }
    },

    allowedDueDates(val) {
      return this.isDateAvailableForDueDateChange(
        val,
        this.currentInvoice.billDate,
        this.currentInvoice.dueDate
      );
    },
  },
};
</script>

<style scoped></style>
