<template>
  <div class="mt-2" v-if="currentInvoice.fixedPeriodProducts.length > 0">
    <div class="mb-2">
      <p class="mb-1">
        <strong>Tuotteet</strong>
      </p>

      <div
        v-for="(product, idx) in currentInvoice.fixedPeriodProducts"
        :key="`${idx}` + 'a'"
        class="product-wrapper"
      >
        <div>
          {{
            replaceProductTags(
              product.desc,
              getFixedPeriodDueDate(currentInvoice),
              getFixedPeriodAccountDate(currentInvoice, startDateExact),
              currentInvoice.language
            )
          }}
        </div>
        <div>{{ formatCurrency(product.amount * product.count) }}</div>
      </div>
    </div>

    <!-- VATS -->
    <vat-component :products="currentInvoice.fixedPeriodProducts" :maxWidth="300"></vat-component>
  </div>
</template>

<script>
import { mapState } from "vuex";
import invoiceMixins from "../../mixins/invoiceMixins";
import mixins from "../../mixins/mixins";
import VatComponent from "@/components/Invoice/VatComponent";

export default {
  mixins: [mixins, invoiceMixins],

  components: {
    VatComponent,
  },

  computed: {
    ...mapState("invoice", ["currentInvoice"]),
  },

  props: {
    startDateExact: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
.product-wrapper {
  max-width: 300px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}
</style>
