<template>
  <div>
    <div v-if="this.currentInvoice.currentRentIncrease">
      <v-row dense>
        <v-col cols="12">
          <h3>Määräaikaiset tuotteet</h3>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="7" lg="6">
          <WarningComponent>
            Laskulla on odottava vuokrankorotus. Et voi luoda määräaikaisia tuotteita
            samanaikaisesti.
          </WarningComponent>
        </v-col>
      </v-row>
    </div>

    <div v-else>
      <!-- Fixed period products -->
      <v-row dense>
        <v-col>
          <h3 class="mb-1">Määräaikaiset tuotteet</h3>
        </v-col></v-row
      >
      <!-- Not in use -->
      <p v-if="!currentInvoice.fixedPeriodRecurrency.inUse">
        Määräaikaisten tuotteiden laskutus ei ole käytössä.
      </p>

      <!-- In use in future -->
      <div v-else-if="fixedPeriodStartInFuture" class="repeat-overview-period mb-1">
        <p>Määräaikaiset tuotteet <strong>odottaa laskutusta</strong></p>
        <p>
          Lasku toistuu
          <strong>{{ currentInvoice.fixedPeriodRecurrency.repeatsLeft }} kertaa.</strong>
        </p>
        <p v-if="startDateExact">
          Laskun ensimmäinen eräpäivä on
          <span class="contract-text font-weight-bold">{{ formatDate(startDateExact) }}</span>
        </p>
        <p>
          Laskun viimeinen eräpäivä on
          <span v-if="lastDueDate" class="contract-text font-weight-bold">{{
            formatDate(lastDueDate)
          }}</span>
        </p>

        <!-- PRODUCTS component -->
        <fixed-period-products :startDateExact="startDateExact"></fixed-period-products>
      </div>

      <!-- Currently in use -->
      <div v-else class="repeat-overview mb-1">
        <strong class="mb-1">Määräaikaisten tuotteiden laskutus on käytössä</strong>
        <p>
          Laskun toistoja jäljellä
          <span class="contract-text font-weight-bold"
            >{{ currentInvoice.fixedPeriodRecurrency.repeatsLeft }} kpl</span
          >
        </p>
        <p v-if="startDateExact">
          Laskun ensimmäinen eräpäivä on
          <span class="contract-text font-weight-bold">{{ formatDate(startDateExact) }}</span>
        </p>
        <p>
          Laskun viimeinen eräpäivä on
          <span v-if="lastDueDate" class="contract-text font-weight-bold">{{
            formatDate(lastDueDate)
          }}</span>
        </p>

        <!-- PRODUCTS component -->
        <fixed-period-products></fixed-period-products>
      </div>

      <v-row class="mb-1" dense>
        <v-col>
          <v-btn color="warning" class="mr-4" @click="openFixedPeriodDialog()"
            >Hallinnoi määräaikaisia tuotteita</v-btn
          >
        </v-col>
      </v-row>

      <!-- Fixed period form dialog -->
      <edit-fixed-period-form
        v-model="fixedPeriodFormDialog"
        :startDateExact="startDateExact"
        :lastDueDate="lastDueDate"
      ></edit-fixed-period-form>
    </div>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import moment from "moment";
import EditFixedPeriodForm from "./EditFixedPeriodForm.vue";
import FixedPeriodProducts from "./FixedPeriodProducts.vue";
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  mixins: [mixins],

  components: {
    EditFixedPeriodForm,
    FixedPeriodProducts,
  },

  data() {
    return {
      fixedPeriodFormDialog: false,
    };
  },

  computed: {
    ...mapState("invoice", ["currentInvoice"]),
    ...mapGetters("invoice", ["fixedPeriodStartInFuture"]),

    startDateExact() {
      // change startdate if not active and in use
      if (
        this.currentInvoice.fixedPeriodRecurrency.inUse &&
        !this.currentInvoice.fixedPeriodRecurrency.active &&
        this.currentInvoice.fixedPeriodRecurrency.startDate
      ) {
        const startMonth = moment(this.currentInvoice.fixedPeriodRecurrency.startDate).format(
          "YYYY-MM"
        );
        const otherDueDate =
          this.currentInvoice.otherDueDates === 100
            ? new Date(moment(startMonth).endOf("month").format("YYYY-MM-DD")).getDate()
            : this.currentInvoice.otherDueDates;

        const nextDueDateMonth = moment(this.currentInvoice.nextDueDate).format("YYYY-MM");
        const startDateYear = new Date(moment(startMonth).format("YYYY-MM")).getFullYear();
        const startDateMonth = new Date(moment(startMonth).format("YYYY-MM")).getMonth();

        if (moment(startMonth).isSameOrBefore(nextDueDateMonth)) {
          // Is next duedate
          return moment(this.currentInvoice.nextDueDate).format("YYYY-MM-DD");
        } else {
          // Is start year and months otherDuedate
          return moment(new Date(startDateYear, startDateMonth, otherDueDate)).format("YYYY-MM-DD");
        }
      } else {
        return null;
      }
    },

    lastDueDate() {
      if (this.currentInvoice.nextDueDate && this.currentInvoice.fixedPeriodRecurrency.startDate) {
        const interval = this.currentInvoice.invoiceInterval;
        const startMonth = moment(this.currentInvoice.fixedPeriodRecurrency.startDate).format(
          "YYYY-MM"
        );
        const nextDueDateMonth = moment(this.currentInvoice.nextDueDate).format("YYYY-MM");
        const startDateYear = new Date(moment(startMonth).format("YYYY-MM-DD")).getFullYear();
        const startDateMonth = new Date(moment(startMonth).format("YYYY-MM-DD")).getMonth();
        const otherDueDate =
          this.currentInvoice.otherDueDates === 100
            ? new Date(moment(startMonth).endOf("month").format("YYYY-MM-DD")).getDate()
            : this.currentInvoice.otherDueDates;
        const repeatsLeft = this.currentInvoice.fixedPeriodRecurrency.repeatsLeft;

        if (repeatsLeft == 1) {
          if (!this.currentInvoice.fixedPeriodRecurrency.active) {
            // Return next possible dueDate
            if (moment(startMonth).isSame(nextDueDateMonth)) {
              return moment(this.currentInvoice.nextDueDate).format("YYYY-MM-DD");
            } else {
              return moment(new Date(startDateYear, startDateMonth, otherDueDate)).format(
                "YYYY-MM-DD"
              );
            }
          } else {
            return moment(this.currentInvoice.nextDueDate).format("YYYY-MM-DD");
          }
        } else {
          let firstDate;
          if (!this.currentInvoice.fixedPeriodRecurrency.active) {
            firstDate = moment(this.currentInvoice.fixedPeriodRecurrency.startDate).format(
              "YYYY-MM"
            );
          } else {
            firstDate = moment(this.currentInvoice.nextDueDate).format("YYYY-MM");
          }
          // Calc total months until end
          const monthsTotalUntilEnd = (repeatsLeft - 1) * interval;
          const lastDate = moment(firstDate).add(monthsTotalUntilEnd, "months").format("YYYY-MM");
          const lastDateYear = new Date(moment(lastDate)).getFullYear();
          const lastDateMonth = new Date(moment(lastDate)).getMonth();
          const otherDueDate =
            this.currentInvoice.otherDueDates === 100
              ? new Date(moment(lastDate).endOf("month").format("YYYY-MM-DD")).getDate()
              : this.currentInvoice.otherDueDates;

          // return last date
          return moment(new Date(lastDateYear, lastDateMonth, otherDueDate)).format("YYYY-MM-DD");
        }
      }
      return null;
    },
  },

  methods: {
    ...mapMutations("invoice", ["freezeFixedPeriodRecurrency"]),

    openFixedPeriodDialog() {
      if (!this.currentInvoice.nextDueDate) {
        this.showPopup(
          "Määritä ensin seuraava eräpäivä, ennenkuin muokkaat määräaikaisia tuotteita",
          "error"
        );
        return;
      }
      this.freezeFixedPeriodRecurrency();
      this.fixedPeriodFormDialog = true;
    },
  },
};
</script>

<style scoped>
.repeat-overview {
  padding: 20px;
  max-width: 500px;
  border: 2px solid var(--v-warning-base);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.repeat-overview-period {
  padding: 20px;
  max-width: 500px;
  border: 2px solid var(--v-warning-base);
  border-radius: 10px;
}
</style>
