<template>
  <div>
    <!-- INACTIVE RENT INCREASE -->
    <div v-if="!currentInvoice.currentRentIncrease.activated">
      <h3 class="mt-1 mb-1">
        Vuokrankorotus tulossa
        <span><v-icon color="warning" medium>mdi-alert-circle</v-icon></span>
      </h3>

      <p v-if="rentIncreaseDueDate">
        Korotetun laskun ensimmäinen eräpäivä (vuokrankorotuspäivä) on
        <strong> {{ formatDate(rentIncreaseDueDate) }}</strong>
      </p>

      <p v-if="rentIncreaseBillDate">
        Lähetyspäivä on
        <strong>
          {{ formatDate(rentIncreaseBillDate) }}
        </strong>

        <span v-if="paymentTermAlert">
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="warning" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
            </template>
            <span
              >Seuraavan laskun ja korotetun laskun lähetyspäivissä pitää olla vähintään 2 päivän
              ero. Pidennä toistuvien laskujen maksuehtoa, siirrä seuraavan laskun eräpäivää tai
              muuta toistuvaa eräpäivää.</span
            >
          </v-tooltip>
        </span>
      </p>

      <p>
        Maksuehto on
        <strong>{{ currentInvoice.currentRentIncrease.paymentTerm }} pv netto</strong> ja
        lähetystapa
        <strong>{{ formatSendType(currentInvoice.currentRentIncrease.sendType) }}</strong>
        <span v-if="sendTypeAlert">
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="warning" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
            </template>
            <span>Seuraavan laskun lähetystapaa vastaavia tietoja puuttuu laskulta.</span>
          </v-tooltip>
        </span>
      </p>

      <p>
        Verollinen loppusumma on <strong>{{ formatCurrency(priceWithTaxes) }}</strong>
      </p>

      <p>
        Sopimuksen mukainen vuokrankorotuksen ajankohta on
        <strong>{{ possibleRentIncreaseMonth }}</strong>
      </p>

      <p>
        Korotukseen merkitty ensimmäinen mahdollinen laskutusajankohta on
        <strong>{{ possibleDueDateMonth }}</strong>
      </p>
    </div>

    <!-- ACTIVATED -->
    <div v-else>
      <h3 class="mb-1 mt-1">
        Vuokrankorotus aktivoitu
        <span><v-icon color="success" medium>mdi-check-circle</v-icon></span>
      </h3>

      <p>Korotetut tuotteet on tallennettu laskulle ja lasku odottaa ensimmäistä lähetystä.</p>

      <p v-if="rentIncreaseDueDate">
        Eräpäivä on
        <strong> {{ formatDate(rentIncreaseDueDate) }} </strong>
        <span v-if="rentIncreaseDueDateAlert">
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="warning" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
            </template>
            <span
              >Korotetut tuotteet on aktivoitu laskulle, mutta olet aikaistanut seuraavaa eräpäivää.
              Voit lähettää korotetut tuotteet vasta {{ possibleDueDateMonth }} alkaen.</span
            >
          </v-tooltip>
        </span>
      </p>

      <p v-if="rentIncreaseBillDate">
        Lähetyspäivä on
        <strong>
          {{ formatDate(rentIncreaseBillDate) }}
        </strong>
      </p>

      <p>
        Maksuehto on <strong>{{ currentInvoice.nextPaymentTerm }} pv netto</strong> ja lähetystapa
        <strong>{{ formatSendType(currentInvoice.nextSendType) }} </strong>
        <span v-if="sendTypeAlert">
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="warning" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
            </template>
            <span>Lähetystapaa vastaavia tietoja puuttuu laskulta.</span>
          </v-tooltip>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import helpers from "@/utils/rentIncreaseHelpers";
import moment from "moment";
import { mapState, mapMutations } from "vuex";
import globalValues from "../../configs/globalValues";

export default {
  mixins: [mixins],

  props: ["paymentTermAlert", "rentIncreaseDueDateAlert", "sendTypeAlert"],

  data() {
    return {
      months: globalValues.months,
    };
  },

  computed: {
    ...mapState("invoice", ["currentInvoice", "watchDueDateChange"]),

    rentIncreaseDueDate() {
      return this.currentInvoice.currentRentIncrease
        ? helpers.getInvoiceDueDate(
            this.currentInvoice,
            this.currentInvoice.currentRentIncrease.originalDueDate
          )
        : null;
    },

    rentIncreaseBillDate() {
      if (this.rentIncreaseDueDate) {
        return helpers.calcRentIncreaseBillDate(this.currentInvoice, this.rentIncreaseDueDate);
      } else {
        return "";
      }
    },

    possibleRentIncreaseMonth() {
      const monthNum = new Date(
        this.currentInvoice.currentRentIncrease.rentIncreaseDate
      ).getMonth();
      const month = this.months[monthNum];
      const year = new Date(this.currentInvoice.currentRentIncrease.rentIncreaseDate).getFullYear();
      return `${month} ${year}`;
    },

    possibleDueDateMonth() {
      const monthNum = new Date(this.currentInvoice.currentRentIncrease.originalDueDate).getMonth();
      const month = this.months[monthNum];
      const year = new Date(this.currentInvoice.currentRentIncrease.originalDueDate).getFullYear();
      return `${month} ${year}`;
    },

    priceWithTaxes() {
      return this.currentInvoice.currentRentIncrease.products.reduce(
        (sum, cur) => sum + cur.amount * cur.count * (1 + cur.taxpr / 100),
        0
      );
    },
  },

  watch: {
    rentIncreaseDueDate: {
      handler(newVal, oldVal) {
        if (this.currentInvoice.currentRentIncrease) {
          // Set to state
          this.setRentIncreaseDates({ field: "rentIncreaseDueDate", val: newVal });

          if (newVal && oldVal && this.watchDueDateChange) {
            if (!moment(newVal).isSame(oldVal))
              this.showPopup(
                `Tulevan vuokrankorotuksen eräpäivää siirretään alkamaan ${moment(
                  this.rentIncreaseDueDate
                ).format(
                  "DD.MM.YYYY"
                )}. Muutos vaikuttaa myös vuokrankorotusdokumentin todelliseen korotuspäivään ja vuokrasopimuksen seuraavaan korotuspäivään.`,
                "info"
              );
          }
        }
      },
    },

    rentIncreaseBillDate(val) {
      this.setRentIncreaseDates({ field: "rentIncreaseBillDate", val });
    },
  },

  methods: {
    ...mapMutations("invoice", ["setRentIncreaseDates"]),
  },
};
</script>

<style></style>
